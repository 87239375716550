/* eslint-disable sort-keys */
export default {
  features: {
    suklWidgetEnabled: false,
    phoneNumberHeaderFooterDisabled: true,

    // Cart
    salesRulesEnabled: true,
    fillCartFromUrlParamsEnabled: true,
    cartItemsMaxQtyNotificationEnabled: true,
    cartItemsStockEnabled: true,
    cartItemsAllowBackOrdersEnabled: true,
    cartItemsRowOriginalPriceEnabled: false,
    salesRuleRemovalEnabled: false,
    totalExcludingVatEnabled: false,
    couponsEnabled: false,
    competitionService: {
      competitionImagePath: '',
      competitionWidgetEnabled: false,
    },
    giftCardsEnabled: false,
    freeShippingBoxEnabled: false,
    freeShippingDescriptionEnabled: true,
    freeShippingViaSalesRulesEnabled: true,
    favoriteMethodsInfoBoxEnabled: false,
    boundProductsEnabled: false,
    displayRxCartNotification: true,
    pickupIdFetchingEnabled: false,
    attributeSetEnabled: true,
    setCartIdFromUrlParamsEnabled: true,

    // Shipping and payment
    orderTypeEnabled: true,
    otcOrderPreferenceEnabled: false,
    reservationsWithRxProductsEnabled: true,
    withPickupTimes: true,
    /**
     * When you want to have OTC delivered, the safe method is Pharmalink.
     * When customer wants another method, he must approve the possible consequences.
     */
    otcDeliveryApproval: {
      enabled: true,
      attributeSetIds: [16, 19, 34, 28, 48],
      /** Methods that can be used as OTC delivery. Thus, they don't need approval. */
      preApprovedMethods: [
        'drmaxshippingpharmalink~drmaxshippingpharmalink',
        'drmaxshippingpharmalink~drmaxshippingpharmalink_cod',
      ],
      /** In case customer rejects to approve. The fallback method is selected. */
      fallbackMethod: 'drmaxshippingpharmalink~drmaxshippingpharmalink',
    },
    deliveryFeEnabled: false,
    paymentFeeEnabled: true,
    vouchersModalEnabled: false,
    removeCartItemsModalEnabled: false,
    reservationInfoBoxEnabled: false,
    reservationsIgnoreStockStatusEnabled: true,
    reservationCheckOpeningHoursEnabled: false,
    pharmacyIsAlwaysReservationEnabled: true,
    pickupPlaceCoordinatesEnabled: true,
    pickupPlaceAddressEnabled: true,
    pickupPlaceOpeningHoursEnabled: false,
    pickupPlaceExtensionAttributesEnabled: false,
    rxOnlyInReservationsEnabled: true,
    useDtcForBoxes: false,
    usePickupSelectV2: false,
    isDrmaxBoxUpdatedFromPickupPlaces: true,
    simpleDeliveryTimeEnabled: true,
    pharmacyWarehouseStockEnabled: true,
    feeForPaymentMethodsHidden: true,

    // Address forms
    fillFormsFromLogin: true,
    persistFormsInLocalStorage: true,
    phoneSlicingEnabled: true,
    streetNumberToMagentoEnabled: true,
    shippingAddressNameEnabled: false,
    countrySelectEnabled: true,
    countrySelectChoiceEnabled: true,
    countrySelectOnlyForCompany: true,
    companyDataPrefillEnabled: false,
    privateInvoiceEnabled: true,
    crnAndVatIdValidationOnlyForDomesticOrdersEnabled: false,
    billingAddressSubheadingEnabled: false,
    optionalBillingInfoForCACOrderEnabled: true,
    optionalBillingInfoForCACReservationEnabled: false,
    customerNotesEnabled: false,

    // Address validation
    addressValidationEnabled: true,

    // Agreements
    cartAgreementsEnabled: true,
    emailSmsAgreementEnabled: true,
    heurekaAgreementEnabled: false,
    personalDataAgreementEnabled: false,
    priceComparisonAgreementEnabled: false,
    staffinoAgreementEnabled: false,
    agreeToPayNoteEnabled: false,

    // Placing orders
    drmaxGatewayMethodEnabled: false,
    gtmOrderInfoGqlEnabled: true,
    placeOrderDeliveryCalculationEnabled: false,
    drmaxCjEventEnabled: false,
    extraDataInPlaceOrderEnabled: true,

    // Thank you pages
    payUSuccessCheckingEnabled: true,
    extraOfferEnabled: false,
    sponsoredProductsLabelEnabled: false,
    dataLayerEventForCeneoEnabled: true,
    nsfSlotEnabled: false,
    bankTransferDetailsDisplayEnabled: true,
  },
}

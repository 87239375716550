import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import carrierCodes from './carrierCodes.mjs'
import config from '../index.mjs'
import features from './features.mjs'
import termsPages from './termsPages.mjs'
import paymentCodes from './paymentCodes.mjs'
import marketplace from './marketplace.mjs'
import inputTypes from './inputTypes.mjs'

export default defineAppConfig({
  ...carrierCodes,
  ...features,
  ...termsPages,
  ...paymentCodes,
  ...marketplace,
  ...inputTypes,
  attributeSetRxId: 21, // Is the same on cloud envs, stage & production
  companyDetailFieldsOrder: {
    crn: 4,
    vatId: 1,
  },
  defaultAgreements: (agreementsTopic) => [
    {
      channel: 'PD',
      topic: agreementsTopic,
      value: 'N',
    },
  ],
  drmaxBrandId: '3477',
  drmaxDefaultPharmacyId: '652',
  firstPartySellerId: '0',
  firstPartySellerName: 'Dr. Max',
  paymentFailedMessageComponent: 'general',
  pharmacyWarehouseId: '2',
  shippingInfoBlocks: '',
  thankYouText: {
    payment: 'email',
    dispatch: 'email',
  },
  ...config,
})
